/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import AutoComplete from 'components/shared/AutoComplete';
import InputPrice from 'components/shared/InputPrice';
import SectionCollapse from 'components/shared/SectionCollapse';
import TypographyCustom from 'components/shared/TypographyCustom';
import { PAYOS_ID } from 'constants/common';
import { JOI } from 'constants/joi';
import { ROUTES } from 'constants/router';
import { useDirection, useDisclosure } from 'hooks';
import { useTranslation } from 'react-i18next';
import { InvoiceUnpaidType, UnpaidInvoiceType } from 'types/invoice.type';
import { PaymentMethodType } from 'types/payment-method.type';
import { GetListTransactionTypeRes } from 'types/property.type';
import { CreateTransactionType } from 'types/transaction.type';
import { formatNumber, formatPrice, showData } from 'utils/common';
import { createSchema, validateSchema } from 'utils/joi';

import { Box, Chip, Grid, Link, Typography } from '@mui/material';
import {
  IconCoin,
  IconCreditCard,
  IconDoor,
  IconNotes,
  IconReceipt,
  IconUsers,
} from '@tabler/icons-react';

interface Props {
  listPaymentMethod: PaymentMethodType[];
  buildingId: number;
  listTransactionTypeIncome: GetListTransactionTypeRes[];
  unpaidInvoiceList: UnpaidInvoiceType[];
  onSubmit?: (payload: CreateTransactionType) => Promise<void>;
  isPayInvoiceInRoom?: boolean;
  invoiceIdInRoom?: string;
  roomId?: string;
}

const PaymentInvoiceForm = forwardRef(
  (
    {
      listPaymentMethod,
      buildingId,
      listTransactionTypeIncome,
      unpaidInvoiceList,
      onSubmit,
      isPayInvoiceInRoom,
      invoiceIdInRoom,
      roomId,
    }: Props,
    ref,
  ) => {
    const { isOpen: isOpenDetail, onToggle } = useDisclosure();
    const { t } = useTranslation();
    const { goToNewTab } = useDirection();
    const [listInvoice, setListInvoice] = useState<InvoiceUnpaidType[]>([]);

    const [formData, setFormData] = useState<CreateTransactionType>({
      buildingId: String(buildingId),
      roomId: '',
      userId: '',
      invoiceId: '',
      paymentMethodId: '',
      transactionAmount: '',
    });

    const [formDataError, setFormDataError] = useState<CreateTransactionType>({
      buildingId: '',
      roomId: '',
      userId: '',
      invoiceId: '',
      paymentMethodId: '',
      transactionAmount: '',
    });

    const schema = createSchema({
      buildingId: JOI.REQUIRE,
      roomId: JOI.REQUIRE,
      userId: JOI.REQUIRE,
      invoiceId: JOI.REQUIRE,
      paymentMethodId: JOI.REQUIRE,
      transactionAmount: JOI.PRICE,
      isRefund: JOI.ALLOW,
    });

    const handleChangeFormData = (key: string) => (value: string | number) => {
      setFormData((pre) => ({
        ...pre,
        userId: key === 'roomId' ? '' : pre.userId,
        invoiceId: key === 'roomId' ? '' : pre.invoiceId,
        paymentMethodId: key === 'invoiceId' ? '' : pre.paymentMethodId,
        [key]: value,
      }));
    };

    const invoiceDetail = useMemo(() => {
      const invoice = listInvoice?.find((invoice) => invoice.id == formData.invoiceId);
      if (invoice) {
        setFormData((prev) => ({
          ...prev,
          transactionAmount: String(
            Number(invoiceDetail?.totalPrice) - Number(invoiceDetail?.transactionAmount),
          ),
        }));

        return invoice;
      }
      setFormData((prev) => ({
        ...prev,
        transactionAmount: '',
      }));

      return null;
    }, [formData.invoiceId, listInvoice]);

    const listUserInRoom = useMemo(() => {
      const listContract = unpaidInvoiceList.find(
        (invoice) => invoice.roomId === String(formData.roomId),
      );
      if (listContract) {
        setFormData((prev) => ({
          ...prev,
          userId: listContract?.invoices?.[0]?.contractPeriod?.contract?.usersInRoom?.[0].userId,
        }));

        return listContract?.invoices?.[0]?.contractPeriod?.contract?.usersInRoom;
      }
    }, [unpaidInvoiceList, formData.roomId]);

    const isPayOS = useMemo(() => {
      const method = listPaymentMethod.find(
        (method) => String(method.id) === String(formData.paymentMethodId),
      );

      if (invoiceDetail && method?.generalPaymentMethods?.id === PAYOS_ID) {
        setFormData((prev) => ({
          ...prev,
          transactionAmount: String(
            Number(invoiceDetail?.totalPrice) - Number(invoiceDetail?.transactionAmount),
          ),
        }));
      }

      return method ? method?.generalPaymentMethods?.id === PAYOS_ID : false;
    }, [formData.paymentMethodId, invoiceDetail, listPaymentMethod]);

    const handleSubmit = async () => {
      const resError = validateSchema(schema, formData);
      setFormDataError(resError.errorMessage);
      if (!resError.isError) {
        onSubmit &&
          (await onSubmit({
            ...formData,
            isRefund: false,
            transactionAmount: String(formatNumber(formData.transactionAmount || '')),
          }));
      }
    };

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    useEffect(() => {
      const getInvoice = async () => {
        let invoiceListInRoom: UnpaidInvoiceType | undefined = {};
        if (isPayInvoiceInRoom) {
          setFormData((prev) => ({
            ...prev,
            invoiceId: invoiceIdInRoom,
            roomId: roomId,
          }));
          invoiceListInRoom = unpaidInvoiceList.find((i) => i.roomId === roomId);
        } else {
          invoiceListInRoom = unpaidInvoiceList.find((i) => i.roomId === formData.roomId);
        }
        if (invoiceListInRoom?.invoices) {
          setListInvoice(invoiceListInRoom?.invoices);
        }
      };
      getInvoice();
    }, [formData.roomId, unpaidInvoiceList, isPayInvoiceInRoom, roomId, invoiceIdInRoom]);

    return (
      <Grid container spacing="12px" mb="12px">
        <Grid container item spacing="12px">
          <Grid item xs={12} sm={6}>
            <AutoComplete
              isRequired
              title={'room'}
              placeHolder="placeholder.choose-room"
              Icon={IconDoor}
              value={String(formData.roomId)}
              options={unpaidInvoiceList.map((invoice) => ({
                label: showData(String(invoice?.roomName)),
                value: String(invoice?.roomId),
              }))}
              onChange={handleChangeFormData('roomId')}
              errorMessage={String(formDataError.roomId)}
              isDisable={isPayInvoiceInRoom}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoComplete
              isRequired
              title={'client'}
              placeHolder="placeholder.choose-client"
              Icon={IconUsers}
              value={String(formData.userId)}
              options={listUserInRoom?.map((user) => ({
                label: showData(String(user.fullName)),
                value: String(user?.userId),
              }))}
              onChange={handleChangeFormData('userId')}
              isDisable={!formData.roomId}
              errorMessage={formDataError.userId}
            />
          </Grid>
        </Grid>
        <Grid container item spacing="12px">
          <Grid item xs={12} sm={6}>
            <AutoComplete
              isRequired
              title={'invoice'}
              placeHolder="placeholder.choose-invoice"
              Icon={IconReceipt}
              value={String(formData.invoiceId)}
              options={listInvoice.map((invoice) => ({
                label: showData(String(invoice.name)),
                value: String(invoice.id),
              }))}
              onChange={handleChangeFormData('invoiceId')}
              isDisable={!formData.roomId || isPayInvoiceInRoom}
              errorMessage={formDataError.invoiceId}
            />
          </Grid>
        </Grid>
        {invoiceDetail && (
          <>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" marginBottom="12px" gap="12px">
                {Number(invoiceDetail?.transactionAmount) > 0 && (
                  <Chip
                    label={`${t('paid')}: ${formatPrice(Number(invoiceDetail?.transactionAmount))}`}
                    color="success"
                  />
                )}
                <Link
                  sx={{
                    cursor: 'pointer',
                    display: 'inline-block',
                  }}
                  underline="none"
                  onClick={goToNewTab(`${ROUTES.INVOICE.LIST}/${invoiceDetail?.id}`)}
                >
                  {t('detail-invoice')}
                </Link>
              </Box>

              <SectionCollapse
                sectionName={`${t('invoice-total')}: ${formatPrice(
                  String(Number(invoiceDetail?.totalPrice)),
                )}`}
                isActive={isOpenDetail}
                onClickOpen={onToggle}
                bodySx={{
                  padding: 0,
                  borderTop: '1px solid var(--gray-03)',
                }}
              >
                <Box p="15px">
                  {invoiceDetail?.contractPeriodFees?.map((fee) => {
                    return (
                      <TypographyCustom
                        key={fee?.id}
                        title={fee?.name}
                        sx={{
                          marginTop: '0',
                        }}
                      >
                        <Typography>
                          {formatPrice(String(fee?.totalPrice))}
                          {fee?.contractFee
                            ? fee?.contractFee?.serviceFeeType?.isMeter
                              ? ` ( ${formatPrice(showData(fee?.unitPrice))}x
                        ${Number(fee?.endingNumber) - Number(fee?.startingNumber)}
                        ${showData(fee?.contractFee?.serviceFeeType?.unit?.nameVi)} )`
                              : ` ( ${formatPrice(showData(fee?.unitPrice))}x
                        ${Number(fee?.amount)}
                        ${showData(fee?.contractFee?.serviceFeeType?.unit?.nameVi)} )`
                            : ''}
                        </Typography>
                      </TypographyCustom>
                    );
                  })}
                </Box>
              </SectionCollapse>
            </Grid>
            <Grid container item spacing="12px">
              <Grid item xs={12} sm={6}>
                <InputPrice
                  isRequired
                  title={'total-payment'}
                  placeholder={'30.000VNĐ...'}
                  maxValue={
                    Number(invoiceDetail?.totalPrice) - Number(invoiceDetail?.transactionAmount)
                  }
                  Icon={IconCoin}
                  value={String(formData.transactionAmount)}
                  onChange={handleChangeFormData('transactionAmount')}
                  isDisable={!invoiceDetail || isPayOS}
                  errorMessage={formDataError.transactionAmount}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AutoComplete
                  isRequired
                  title={'payment-method'}
                  placeHolder="placeholder.choose-payment-method"
                  Icon={IconCreditCard}
                  value={String(formData.paymentMethodId)}
                  options={listPaymentMethod.map((method) => ({
                    label: showData(String(method.name)),
                    value: String(method.id),
                  }))}
                  onChange={handleChangeFormData('paymentMethodId')}
                  isDisable={!invoiceDetail}
                  errorMessage={formDataError.paymentMethodId}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  },
);

export default React.memo(PaymentInvoiceForm);
