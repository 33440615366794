import { centralGW } from 'services/axios.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { AssignUser, UserReq, UserType } from 'types/user.type';

export const getListUser = (payload: CommonReq & PagingReq) => {
  const { buildingId, page, limit, query, isActive } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}`, {
    params: {
      page,
      limit,
      ...(query ? { q: query } : {}),
      isActive: isActive,
    },
    isDisableToast: true,
  });
};

export const getListUserInBuilding = (payload: CommonReq & PagingReq) => {
  const { buildingId, isActive } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}`, {
    params: {
      isFull: true,
      isActive: isActive,
    },
    isDisableToast: true,
    isDisableLoading: true,
  });
};

export const deleteUser = (payload: CommonReq & UserReq) => {
  const { contractId, userId } = payload;

  return centralGW.delete(`/api/v1/user/${userId}/${contractId}/unassign`, {});
};

export const updateUser = (payload: CommonReq & UserType) => {
  const { id: userId, phoneNumber, email, isActive, buildingId } = payload;

  return centralGW.put(`/api/v1/user/${userId}/building/${buildingId}`, {
    phoneNumber,
    ...(email ? { email } : { email: null }),
    isActive,
  });
};

export const createUser = async (payload: CommonReq & UserType) => {
  const {
    buildingId,
    firstName,
    lastName,
    gender,
    placeOfOrigin,
    placeOfResidence,
    phoneNumber,
    email,
    nationality,
    birthday,
    identityNumber,
    password,
    idCardImages,
  } = payload;

  return centralGW.post(`/api/v1/user/building/${buildingId}/register`, {
    firstName,
    lastName,
    gender,
    placeOfOrigin,
    placeOfResidence,
    phoneNumber,
    ...(email ? { email } : {}),
    nationality,
    birthday,
    identityNumber,
    idCardImages,
    password,
  });
};

export const getUserDetail = (payload: UserReq) => {
  const { userId } = payload;

  return centralGW.get(`/api/v1/user/${userId}/detail`, {
    isDisableToast: true,
  });
};

export const assignUserToRoom = async (payload: CommonReq & AssignUser) => {
  const { userId, contractId, startAt, endAt, note } = payload;

  return centralGW.post(`/api/v1/user/${userId}/contract/${contractId}/assign`, {
    startAt,
    endAt,
    note,
  });
};

export const unassignUserToRoom = async (payload: CommonReq) => {
  const { userId, contractId } = payload;

  return centralGW.delete(`/api/v1/user/${userId}/contract/${contractId}/unassign`, {});
};

export const getOverviewInvoiceByClient = (payload: CommonReq) => {
  const { userId } = payload;

  return centralGW.get(`/api/v1/user/${userId}/invoices/overview`, {
    isDisableToast: true,
  });
};
