import { IdCardReq } from 'types/client.type';
import { centralGW } from './axios.service';
import { CommonReq } from 'types/common.type';

export const fptRecognizeIdCard = async (payload: IdCardReq) => {
  const formData = new FormData();
  formData.append('image', payload.image);
  formData.append('isFront', payload.isFront ? 'true' : 'false');

  return centralGW.post('/api/v1/fpt/recognize-id/upload', formData);
};

export const deleteClient = (payload: CommonReq) => {
  const { userId, buildingId } = payload;

  return centralGW.delete(`/api/v1/user/${userId}/building/${buildingId}`);
};

export const fptRecognizeBackIdCard = async (payload: {
  identityNumberPath: string;
  accountId: number;
}) => {
  const { accountId, identityNumberPath } = payload;

  return centralGW.post(`/api/v1/auth/account/${accountId}/id-card/backside`, {
    identityNumberPath,
  });
};
