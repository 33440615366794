/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import DialogWrapper from 'components/dialog/DialogWrapper';
import PaymentDepositForm from 'components/forms/PaymentDepositForm';
import PaymentInvoiceForm from 'components/forms/PaymentInvoiceForm';
import PaymentOtherForm from 'components/forms/PaymentOtherForm';
import AutoComplete from 'components/shared/AutoComplete';
import RadioGroup from 'components/shared/RadioGroup';
import { PAYMENT_TYPE } from 'enums';
import { useSelector } from 'react-redux';
import { invoiceSelector } from 'store/invoice';
import { propertySelector } from 'store/property';
import { CreateOtherTransactionType, CreateTransactionType } from 'types/transaction.type';
import { showData } from 'utils/common';

import { Grid } from '@mui/material';
import { IconBuildingEstate } from '@tabler/icons-react';
import { PERMISSION } from 'constants/permission';
import { dispatch } from 'store/Store';
import { clearImage, kanbanSelector } from 'store/kanban';
import ImagePopup from 'components/shared/ImagePopup';
import { useDisclosure } from 'hooks';
import { clearTabList, saveTabList, transactionSelector } from 'store/transaction';
import { IMAGE_URL } from 'constants/common';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (payload: CreateTransactionType) => Promise<void>;
  onSubmitDeposited?: (payload: CreateTransactionType) => void;
  onSubmitOther?: (payload: CreateOtherTransactionType) => void;
  handleUploadFile?: (image: File[]) => void;
  isPayInvoiceInRoom?: boolean;
  invoiceIdInRoom?: string;
  roomId?: string;
  isTransition?: boolean;
};

function CreateIncomeTransactionModal({
  isOpen = false,
  onClose,
  onSubmit,
  onSubmitDeposited,
  onSubmitOther,
  handleUploadFile,
  isPayInvoiceInRoom = false,
  invoiceIdInRoom,
  roomId,
  isTransition = true,
}: Props) {
  const submitRef = useRef<{ handleSubmit: () => void }>();
  const submitDepositedRef = useRef<{ handleSubmit: () => void }>();
  const submitOtherRef = useRef<{ handleSubmit: () => void }>();
  const { buildingsActiveInBranch, buildingId, listTransactionTypeIncome, listPaymentMethod } =
    useSelector(propertySelector);
  const { listDepositInvoiceInBuilding, unpaidInvoiceList } = useSelector(invoiceSelector);
  const { tabListValue } = useSelector(transactionSelector);
  const { images } = useSelector(kanbanSelector);
  const [image, setOpenImage] = useState('');

  const {
    isOpen: isOpenImage,
    onClose: onCloseImage,
    onOpen: onOpenDeleteTabConfirm,
  } = useDisclosure();

  const handleSubmitInvoice = () => {
    if (submitRef.current) {
      submitRef.current.handleSubmit();
    }
  };

  const handleSubmitDeposited = () => {
    if (submitDepositedRef.current) {
      submitDepositedRef.current.handleSubmit();
    }
  };

  const handleSubmitOther = () => {
    if (submitOtherRef.current) {
      submitOtherRef.current.handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (tabListValue === PAYMENT_TYPE.INVOICE) handleSubmitInvoice();
    else if (tabListValue === PAYMENT_TYPE.DEPOSIT) handleSubmitDeposited();
    else {
      handleSubmitOther();
    }
  };

  const handleChangePaymentType = (value: string) => {
    dispatch(saveTabList(value));
  };

  const handleDirectToImage = (url: string) => {
    if (url) {
      setOpenImage(url);
      onOpenDeleteTabConfirm();
    }
  };
  const handleDelete = (index: number) => {
    dispatch(clearImage(index));
  };

  return (
    <DialogWrapper
      title="bill-payment"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      titleSx={{
        bgcolor: 'var(--primary)',
        color: 'white',
      }}
      isTransition={false}
    >
      <Grid container spacing="12px" mb="12px">
        <Grid container item spacing="12px">
          <Grid item xs={12} sm={6}>
            <RadioGroup
              title="payment-type"
              value={tabListValue}
              onChange={handleChangePaymentType}
              options={[
                {
                  value: PAYMENT_TYPE.INVOICE,
                  label: 'invoice',
                  permission: [PERMISSION.CREATE_INVOICE_TRANSACTION],
                },
                {
                  value: PAYMENT_TYPE.DEPOSIT,
                  label: 'deposit-payment',
                  permission: [PERMISSION.CREATE_DEPOSIT_TRANSACTION],
                },
                {
                  value: PAYMENT_TYPE.OTHER,
                  label: 'other-income',
                  permission: [PERMISSION.CREATE_OTHER_TRANSACTION],
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoComplete
              isRequired
              title={'building'}
              placeHolder="placeholder.choose-building"
              Icon={IconBuildingEstate}
              isDisable
              value={String(buildingId)}
              options={buildingsActiveInBranch.map((building) => ({
                label: showData(String(building.name)),
                value: String(building.id),
              }))}
            />
          </Grid>
        </Grid>
        <ImagePopup
          isOpen={isOpenImage}
          onClose={onCloseImage}
          images={images.map((img) => String(`${IMAGE_URL}?id=${img.filePath}`))}
        />
      </Grid>
      {tabListValue === PAYMENT_TYPE.INVOICE ? (
        <PaymentInvoiceForm
          ref={submitRef}
          buildingId={buildingId}
          unpaidInvoiceList={unpaidInvoiceList}
          listPaymentMethod={listPaymentMethod}
          listTransactionTypeIncome={listTransactionTypeIncome}
          onSubmit={onSubmit}
          isPayInvoiceInRoom={isPayInvoiceInRoom}
          invoiceIdInRoom={invoiceIdInRoom}
          roomId={roomId}
        />
      ) : tabListValue === PAYMENT_TYPE.DEPOSIT ? (
        <PaymentDepositForm
          ref={submitDepositedRef}
          buildingId={buildingId}
          listPaymentMethod={listPaymentMethod}
          listDepositInvoiceInBuilding={listDepositInvoiceInBuilding}
          onSubmit={onSubmitDeposited}
        />
      ) : (
        <PaymentOtherForm
          ref={submitOtherRef}
          buildingId={buildingId}
          listPaymentMethod={listPaymentMethod}
          onSubmit={onSubmitOther}
          images={images}
          handleDirectToImage={handleDirectToImage}
          handleDelete={handleDelete}
          onUpload={handleUploadFile}
        />
      )}
    </DialogWrapper>
  );
}

export default React.memo(CreateIncomeTransactionModal);
