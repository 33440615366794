/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';

import Scrollbar from 'components/custom-scroll/Scrollbar';
import BlankCard from 'components/shared/BlankCard';
import InvoiceChip from 'components/shared/InvoiceChip';
import { DEFAULT_LIMIT, ROW_PER_PAGE_OPTIONS } from 'constants/common';
import { ROUTES } from 'constants/router';
import { INVOICE_STATUS } from 'enums';
import { useDirection } from 'hooks';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GetInvoiceType, InvoicesType } from 'types/invoice.type';
import { GetListServiceRes } from 'types/property.type';
import { DropDownTableProps } from 'types/table.type';
import { formatDate, formatPrice, isAllow, isMobile, showData } from 'utils/common';

import {
  Box,
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

import ExpandAction from '../shared/ExpandAction';
import { PERMISSION } from 'constants/permission';

type HeaderProps = {
  isMeter?: boolean;
  feeName?: string;
};

type Props = {
  services?: GetListServiceRes[];
  invoices?: InvoicesType[];
  headers?: string[];
  firstHeaders?: string[];
  secondHeaders?: string[];
  disableButton?: boolean;
  onOpenIncomeModal?: (invoiceId: number) => void;
  onOpenConfirmModal?: (invoiceId: number) => void;
  fixedAmount?: number;
  isConfirm?: boolean;
  disableDirect?: boolean;
};

const HeaderGroup = ({ isMeter = false }: HeaderProps) => {
  const { t } = useTranslation();
  const subColumnQuantity = ['unit-price', 'quantity', 'total-price'];
  const subColumnMeter = ['unit-price', 'first-number', 'last-number', 'total-price'];

  return (
    <>
      {isMeter
        ? subColumnMeter?.map((col) => {
            return (
              <TableCell
                key={uniqueId()}
                sx={{
                  textAlign: 'center',
                  p: 0,
                  py: '8px',
                  minWidth: 'calc(300px / 4)',
                  borderRight: '1px solid var(--gray-05)',
                }}
              >
                {t(col)}
              </TableCell>
            );
          })
        : subColumnQuantity?.map((col) => {
            return (
              <TableCell
                key={uniqueId()}
                sx={{
                  textAlign: 'center',
                  p: 0,
                  py: '8px',
                  minWidth: 'calc(200px /3)',
                  borderRight: '1px solid var(--gray-05)',
                }}
              >
                {t(col)}
              </TableCell>
            );
          })}
    </>
  );
};

const InvoiceFeeDetailTable = ({
  services = [],
  invoices = [],
  header = [],
  firstHeaders = [],
  secondHeaders = [],
  currentPage = 1,
  isAction = false,
  disableButton = false,
  fixedAmount = 1,
  total = 0,
  limit = DEFAULT_LIMIT,
  isConfirm = false,
  disableDirect = false,
  onChangePage,
  onChangeLimit,
  onOpenIncomeModal,
  onOpenConfirmModal,
}: Props & DropDownTableProps) => {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const theme = useTheme();
  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const [tableFormData, setTableFormData] = useState<{ [key: string]: GetInvoiceType }>({});

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    onChangePage && onChangePage(page);
  };

  const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChangeLimit && onChangeLimit(Number(event.target.value));
  };

  const handleOpenIncomeModal = (invoiceId: number) => () => {
    onOpenIncomeModal && onOpenIncomeModal(invoiceId);
  };
  const handleOpenConfirmModal = (invoiceId: number) => () => {
    onOpenConfirmModal && onOpenConfirmModal(invoiceId);
  };

  useEffect(() => {
    const result: { [key: string]: GetInvoiceType } = {};

    invoices?.forEach((template) => {
      result[String(template.id)] = {
        id: template.id,
        contract: {
          discount: 0,
          unitPrice:
            template?.contractPeriodFees &&
            template?.contractPeriodFees?.length > 0 &&
            template?.contractPeriodFees[0]
              ? Number(template?.contractPeriodFees[0].unitPrice)
              : 0,
          isEnable: true,
        },
        contractFees: template?.contractPeriodFees?.map((fee) => {
          return {
            id: Number(fee.contractFeeId),
            isEnable: true,
            isMeter: Boolean(fee?.contractFee?.serviceFeeType?.isMeter),
            discount: 0,
            unitPrice: Number(fee?.unitPrice || 0),
            amount: Number(fee?.amount || 0),
            startingNumber: Number(fee?.startingNumber || 0),
            endingNumber: Number(fee?.endingNumber || 0),
            feeTypeId: fee?.contractFee?.serviceFeeType?.id,
          };
        }),
      };
    });
    setTableFormData(result);
  }, [invoices]);
  const positionColumnInRoom = (index: number) => {
    if (isConfirm) {
      return index === 1
        ? 101
        : index === 2
        ? 251
        : index === 3
        ? 361
        : index === 4
        ? 459
        : index * 80;
    } else {
      return index === 1
        ? 101
        : index === 2
        ? 251
        : index === 3
        ? 361
        : index === 4
        ? 459
        : index * 80;
    }
  };
  const handleDirectToInvoiceDetail = (invoiceId: string) => () => {
    if (!disableDirect) {
      goTo(`${ROUTES.INVOICE.LIST}/${invoiceId}`)();
    }
  };

  return (
    <BlankCard>
      <TableContainer component={Paper}>
        <Scrollbar
          sx={{
            position: 'relative',
          }}
        >
          <Table
            sx={{
              minWidth: 750,
              whiteSpace: {
                xs: 'nowrap',
                sm: 'unset',
              },
              '.MuiTableCell-head': {
                p: '8px 16px',
              },
              '.MuiInputBase-input': {
                textAlign: 'center',
              },
            }}
            size="medium"
          >
            <TableHead
              sx={{
                position: mdDown ? 'block' : 'sticky',
                top: '0',
              }}
            >
              <TableRow>
                {firstHeaders.map((header, index) => (
                  <TableCell
                    key={uniqueId()}
                    rowSpan={2}
                    sx={{
                      minWidth:
                        (index === 3 || index === 0) && invoices.length === 0
                          ? '101px'
                          : index === 2
                          ? '110px'
                          : index === 1
                          ? '150px'
                          : '80px',
                      textAlign: 'center',
                      border: '1px solid var(--gray-05)',
                      position: (isConfirm ? index <= 4 : index <= 3)
                        ? mdDown
                          ? 'block'
                          : 'sticky'
                        : 'initial',
                      left: positionColumnInRoom(index),
                      backgroundColor: index <= 4 ? 'var(--header-table-color)' : 'inherit',
                      boxShadow: `1px 0 0 ${theme.palette.divider} inset`,
                      zIndex: index <= 4 ? 1 : 0,
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
                <TableCell
                  key={uniqueId()}
                  colSpan={3}
                  sx={{ textAlign: 'center', border: '1px solid var(--gray-05)' }}
                >
                  {t('rent-fee')}
                </TableCell>
                {services?.map((service) => {
                  return (
                    <TableCell
                      key={uniqueId()}
                      colSpan={service.isMeter ? 4 : 3}
                      sx={{
                        textAlign: 'center',
                        border: '1px solid var(--gray-05)',
                      }}
                    >
                      {showData(service.name)}
                    </TableCell>
                  );
                })}
                {secondHeaders.map((header, index) => (
                  <TableCell
                    key={uniqueId()}
                    rowSpan={2}
                    sx={{
                      minWidth: index === 0 ? '130px' : '90px',
                      textAlign: 'center',
                      border: '1px solid var(--gray-05)',
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
                {isAction && (
                  <TableCell
                    key={uniqueId()}
                    rowSpan={2}
                    sx={{
                      textAlign: 'center',
                      minWidth: '70px',
                      position: mdDown ? 'block' : 'sticky',
                      right: 0,
                      backgroundColor: 'var(--header-table-color)',
                      boxShadow: `1px 0 0 ${theme.palette.divider} inset`,
                      zIndex: 1,
                    }}
                  >
                    {t('action')}
                  </TableCell>
                )}
              </TableRow>
              <TableRow>
                <HeaderGroup />
                {services?.map((service) => {
                  return <HeaderGroup key={uniqueId()} isMeter={service.isMeter} />;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.map((template) => {
                const invoiceFee = tableFormData[Number(template.id)];
                const isPaid =
                  template.status === INVOICE_STATUS.PAID ||
                  template.status === INVOICE_STATUS.PARTIAL ||
                  template.status === INVOICE_STATUS.CANCELED;
                const isUnpaid =
                  template.status === INVOICE_STATUS.PAID ||
                  template.status === INVOICE_STATUS.WAITING_FOR_CONFIRM ||
                  template.status === INVOICE_STATUS.CANCELED;

                return (
                  <TableRow key={template.id}>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        minWidth: '80px',
                        position: mdDown ? 'block' : 'sticky',
                        left: 0,
                        backgroundColor: 'white',
                        boxShadow: `1px 0 0 ${theme.palette.divider} inset`,
                        zIndex: 1,
                      }}
                    >
                      <Link
                        key={uniqueId()}
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleDirectToInvoiceDetail(String(template.id))}
                      >
                        {showData(template?.name)}
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        minWidth: '150px',
                        position: mdDown ? 'block' : 'sticky',
                        left: 101,
                        backgroundColor: 'white',
                        boxShadow: `1px 0 0 ${theme.palette.divider} inset`,
                        zIndex: 1,
                      }}
                    >
                      <Link
                        key={uniqueId()}
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleDirectToInvoiceDetail(String(template.id))}
                      >
                        <InvoiceChip key={uniqueId()} status={template.status} />
                      </Link>
                    </TableCell>

                    {/* <TableCell
                      sx={{
                        textAlign: 'center',
                        minWidth: '80px',
                        position: mdDown ? 'block' : 'sticky',
                        left: 230,
                        backgroundColor: 'white',
                        boxShadow: `1px 0 0 ${theme.palette.divider} inset`,
                        zIndex: 1,
                      }}
                    >
                      <Link
                        key={uniqueId()}
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleDirectToInvoiceDetail(String(template.id))}
                        color="textSecondary"
                      >
                        <Typography key={uniqueId()} fontSize={12}>
                          {showData(template?.name)}
                        </Typography>
                      </Link>
                    </TableCell> */}
                    {isConfirm && (
                      <>
                        <TableCell
                          sx={{
                            textAlign: 'center',
                            minWidth: '95px',
                            position: mdDown ? 'block' : 'sticky',
                            left: 251,
                            backgroundColor: 'white',
                            boxShadow: `1px 0 0 ${theme.palette.divider} inset`,
                            zIndex: 1,
                          }}
                        >
                          <Link
                            key={uniqueId()}
                            onClick={goTo(
                              `${ROUTES.ROOM.LIST}/${
                                isAllow([PERMISSION.GET_ROOM_DETAIL])
                                  ? template.infoRoom?.room?.id
                                  : null
                              }`,
                            )}
                            underline="none"
                            sx={{ cursor: 'pointer', fontSize: 14 }}
                          >
                            {showData(template.infoRoom?.room?.name)}
                          </Link>
                        </TableCell>
                      </>
                    )}
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        minWidth: '98px',
                        position: mdDown ? 'block' : 'sticky',
                        left: isConfirm ? 361 : 251,
                        backgroundColor: 'white',
                        boxShadow: `1px 0 0 ${theme.palette.divider} inset`,
                        zIndex: 1,
                      }}
                    >
                      <Link
                        key={uniqueId()}
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleDirectToInvoiceDetail(String(template.id))}
                        color="textSecondary"
                      >
                        <Typography key={uniqueId()} textAlign="center">
                          {template.contractPeriod?.name}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        position: mdDown ? 'block' : 'sticky',
                        left: isConfirm ? 459 : 361,
                        backgroundColor: 'white',
                        boxShadow: `1px 0 0 ${theme.palette.divider} inset`,
                        zIndex: 1,
                      }}
                    >
                      <Link
                        key={uniqueId()}
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleDirectToInvoiceDetail(String(template.id))}
                        color="textSecondary"
                      >
                        <Typography key={uniqueId()}>
                          {showData(formatPrice(Number(template.totalPrice)))}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        border: '1px solid var(--gray-05)',
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      <Link
                        key={uniqueId()}
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleDirectToInvoiceDetail(String(template.id))}
                        color="textSecondary"
                      >
                        <Typography key={uniqueId()}>
                          {showData(formatPrice(Number(template.transactionAmount)))}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        border: '1px solid var(--gray-05)',
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      <Link
                        key={uniqueId()}
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleDirectToInvoiceDetail(String(template.id))}
                        color="textSecondary"
                      >
                        <Typography key={uniqueId()}>
                          {showData(formatPrice(Number(template.refundTransactionAmount)))}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        border: '1px solid var(--gray-05)',
                      }}
                    >
                      <Link
                        key={uniqueId()}
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleDirectToInvoiceDetail(String(template.id))}
                        color="textSecondary"
                      >
                        <Typography key={uniqueId()}>
                          {showData(formatPrice(Number(invoiceFee?.contract?.unitPrice)))}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        border: '1px solid var(--gray-05)',
                      }}
                    >
                      {1}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        border: '1px solid var(--gray-05)',
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      {formatPrice(String(invoiceFee?.contract?.unitPrice))}
                    </TableCell>
                    {services?.map((service) => {
                      const existedFee = invoiceFee?.contractFees?.find(
                        (fee) => fee.feeTypeId == service?.id,
                      );

                      if (service?.isMeter) {
                        return (
                          <Fragment key={service.id}>
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                border: '1px solid var(--gray-05)',
                              }}
                            >
                              {showData(
                                existedFee ? (
                                  <Link
                                    key={uniqueId()}
                                    underline="none"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleDirectToInvoiceDetail(String(template.id))}
                                    color="textSecondary"
                                  >
                                    <Typography>
                                      {formatPrice(String(existedFee?.unitPrice))}
                                    </Typography>
                                  </Link>
                                ) : null,
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                border: '1px solid var(--gray-05)',
                              }}
                            >
                              {showData(
                                existedFee ? (
                                  <Link
                                    key={uniqueId()}
                                    underline="none"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleDirectToInvoiceDetail(String(template.id))}
                                    color="textSecondary"
                                  >
                                    <Typography>{showData(existedFee?.startingNumber)}</Typography>
                                  </Link>
                                ) : null,
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                border: '1px solid var(--gray-05)',
                              }}
                            >
                              {showData(
                                existedFee ? (
                                  <Link
                                    key={uniqueId()}
                                    underline="none"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleDirectToInvoiceDetail(String(template.id))}
                                    color="textSecondary"
                                  >
                                    <Typography>{showData(existedFee?.endingNumber)}</Typography>
                                  </Link>
                                ) : null,
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                border: '1px solid var(--gray-05)',
                                fontWeight: 'bold',
                                fontSize: 14,
                              }}
                            >
                              {showData(
                                existedFee ? (
                                  <Link
                                    key={uniqueId()}
                                    underline="none"
                                    sx={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 14 }}
                                    onClick={handleDirectToInvoiceDetail(String(template.id))}
                                    color="textSecondary"
                                  >
                                    {showData(
                                      formatPrice(
                                        Number(existedFee?.unitPrice) *
                                          (Number(existedFee?.endingNumber) -
                                            Number(existedFee?.startingNumber)),
                                      ),
                                    )}
                                  </Link>
                                ) : null,
                              )}
                            </TableCell>
                          </Fragment>
                        );
                      } else {
                        return (
                          <Fragment key={service.id}>
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                border: '1px solid var(--gray-05)',
                              }}
                            >
                              {showData(
                                existedFee ? (
                                  <Link
                                    key={uniqueId()}
                                    underline="none"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleDirectToInvoiceDetail(String(template.id))}
                                    color="textSecondary"
                                  >
                                    <Typography>
                                      {showData(formatPrice(String(existedFee?.amount)))}
                                    </Typography>
                                  </Link>
                                ) : null,
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                border: '1px solid var(--gray-05)',
                              }}
                            >
                              {showData(
                                existedFee ? (
                                  <Link
                                    key={uniqueId()}
                                    underline="none"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleDirectToInvoiceDetail(String(template.id))}
                                    color="textSecondary"
                                  >
                                    <Typography>
                                      {showData(formatPrice(String(existedFee?.amount)))}
                                    </Typography>
                                  </Link>
                                ) : null,
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                border: '1px solid var(--gray-05)',
                                fontWeight: 'bold',
                                fontSize: 14,
                              }}
                            >
                              {showData(
                                existedFee ? (
                                  <Link
                                    key={uniqueId()}
                                    underline="none"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleDirectToInvoiceDetail(String(template.id))}
                                    color="textSecondary"
                                  >
                                    {formatPrice(
                                      Number(existedFee?.unitPrice) * Number(existedFee?.amount),
                                    )}
                                  </Link>
                                ) : null,
                              )}
                            </TableCell>
                          </Fragment>
                        );
                      }
                    })}
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        border: '1px solid var(--gray-05)',
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      <Link
                        key={uniqueId()}
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleDirectToInvoiceDetail(String(template.id))}
                        color="textSecondary"
                      >
                        <Typography key={uniqueId()}>{formatDate(template.expiredAt)}</Typography>
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        border: '1px solid var(--gray-05)',
                      }}
                    >
                      <Link key={uniqueId()} href="#" underline="none">
                        {showData(
                          template.contractFeeTransactions?.map((ele) => ele?.id).join(', '),
                        )}
                      </Link>
                    </TableCell>
                    {isConfirm && (
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          border: '1px solid var(--gray-05)',
                        }}
                      >
                        <Link key={uniqueId()} href="#" underline="none">
                          {showData(template.createdBy?.fullName)}
                        </Link>
                      </TableCell>
                    )}

                    {isAction && (
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          border: '1px solid var(--gray-05)',
                          fontWeight: 'bold',
                          fontSize: 14,
                          position: mdDown ? 'block' : 'sticky',
                          right: 0,
                          backgroundColor: 'white',
                          zIndex: 1,
                        }}
                      >
                        <ExpandAction key={`popover-${template.id}`}>
                          <Box display="flex" flexDirection="column" gap="6px">
                            <Button
                              color="info"
                              variant="contained"
                              onClick={handleOpenIncomeModal(Number(template.id))}
                              disabled={isUnpaid || disableButton}
                            >
                              {t('payment')}
                            </Button>
                            <Button
                              color="info"
                              variant="outlined"
                              onClick={goTo(`${ROUTES.INVOICE.LIST}/${template.id}`)}
                            >
                              {t('detail')}
                            </Button>
                            <Button
                              color="error"
                              variant="contained"
                              onClick={handleOpenConfirmModal(Number(template.id))}
                              disabled={isPaid || disableButton}
                            >
                              {t('cancel')}
                            </Button>
                          </Box>
                        </ExpandAction>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
        {!invoices.length && (
          <Box width="100%" display="flex" justifyContent="center" p={5}>
            <Typography>{t('no-data')}</Typography>
          </Box>
        )}
        <TablePagination
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
          rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
          colSpan={header.length + 1}
          count={total}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeLimit}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </BlankCard>
  );
};

export default React.memo(InvoiceFeeDetailTable);
